export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'
export const SAVE_FORM_DATA = 'form//SAVE_FORM_DATA'

export function saveFormData(data) {
  return {
    type: SAVE_FORM_DATA,
    payload: data,
  }
}

export function resetFormData() {
  return {
    type: RESET_FORM_DATA,
  }
}

export function getFormData(store) {
  return store.form.data
}

const initialState = {
  data: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        data: {},
      }

    case SAVE_FORM_DATA:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
